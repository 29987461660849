import { createSlice } from "@reduxjs/toolkit"
import {
  isAdminDeleteEnabled,
  isFolderRefreshEnabled,
  isJsonViewEnabled,
} from "../adminControls"
import { ControlsState } from "./types"

const state = createSlice({
  name: "controls",
  initialState: {
    deleteEnabled: isAdminDeleteEnabled(),
    folderVerifyEnabled: isFolderRefreshEnabled(),
    jsonViewerEnabled: isJsonViewEnabled(),
  } as ControlsState,
  reducers: {
    enableDelete(state) {
      state.deleteEnabled = true
    },
    disableDelete(state) {
      state.deleteEnabled = false
    },
    enableFolderVerify(state) {
      state.folderVerifyEnabled = true
    },
    disableFolderVerify(state) {
      state.folderVerifyEnabled = false
    },
    enableJsonView(state) {
      state.jsonViewerEnabled = true
    },
    disableJsonView(state) {
      state.jsonViewerEnabled = false
    },
  },
})

export const controlsActions = state.actions
export const controlsReducer = state.reducer
export const controlsName = state.name
