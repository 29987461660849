import { call, put } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { ChangePasswordInput } from "../state/types"
import { authActions } from "../state"
import { extractUserProfile } from "../converters/user"

export function* changePasswordSaga(
  action: PayloadAction<ChangePasswordInput>
) {
  try {
    const api = yield* getSagaContext("api")
    const result = yield* call(api.auth.changePassword, {
      newPassword: action.payload.newPassword,
    })
    if (result.error) {
      yield* put(authActions.changePasswordFailure(result.error))
      return
    }

    const profile = yield* call(api.auth.profile)
    yield* put(authActions.changePasswordSuccess())
    yield* put(authActions.setProfile(extractUserProfile(profile)))
  } catch (error) {
    yield* put(authActions.changePasswordFailure())
  }
}
