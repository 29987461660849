import React from "react"
import ErrorMessage from "../ErrorMessage"

export interface ErrorsWrapperProps {
  fullScreen?: boolean
  children: any
}

export class ErrorsWrapper extends React.Component<ErrorsWrapperProps> {
  constructor(props: ErrorsWrapperProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo })
  }

  handleClick = () => {
    this.setState({ hasError: false })
  }
  render() {
    if ((this.state as any).hasError) {
      // render fallback UI
      return (
        <ErrorMessage
          fullScreen={this.props.fullScreen}
          onRetry={this.handleClick}
        />
      )
    }

    return (this.props as any).children
  }
}
