import clsx from "clsx"
import React, { ButtonHTMLAttributes } from "react"
import Spinner from "../Spinner"
import styles from "./Button.module.css"

export type ButtonColor =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "light"
  | "success"
  | "info"
  | "accent"

export type ButtonSize = "small" | "default" | "large"

export interface ButtonProps extends ButtonHTMLAttributes<unknown> {
  children: any
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  loading?: boolean
  iconOnly?: boolean
  size: ButtonSize
  color: ButtonColor
}

const Button = ({
  children,
  className,
  fullWidth,
  loading,
  iconOnly,
  color,
  size,
  disabled,
  onClick,
  ...other
}: ButtonProps) => {
  const handleClick = (e: any) => {
    if (loading === true) {
      return
    }

    onClick?.(e)
  }

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={clsx(
        "btn font-regular",
        styles.root,
        {
          "btn-accent": color === "accent",
          "btn-primary": color === "primary",
          "btn-secondary": color === "secondary",
          "btn-success": color === "success",
          "btn-info": color === "info",
          "btn-warning": color === "warning",
          "btn-error": color === "error",
          "btn-ghost": color === "light",
          "btn-sm": size === "small",
          "btn-lg": size === "large",
          "btn-square px-1 flex items-center justify-center": iconOnly,
          [styles.disabled]: disabled,
          [styles.primaryDisabled]: disabled && color === "primary",
          [styles.secondaryDisabled]: disabled && color === "secondary",
          [styles.successDisabled]: disabled && color === "success",
          [styles.errorDisabled]: disabled && color === "error",
          [styles.infoDisabled]: disabled && color === "info",
          [styles.warningDisabled]: disabled && color === "warning",
          "w-full": fullWidth,
        },
        className
      )}
      {...other}
    >
      {loading ? (
        <Spinner
          className={clsx({
            "mr-3": !iconOnly,
          })}
        />
      ) : (
        <></>
      )}
      <>{(!iconOnly || !loading) && children}</>
    </button>
  )
}

Button.defaultProps = {
  color: "default",
  size: "default",
}

export default Button
