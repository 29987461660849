import { AppThemeContext } from "./context"
import ThemeStyles from "./styles"
import { ThemeMetaImport } from "./meta"
import { ThemeFontsImport } from "./fonts"
import React from "react"
import { HeadWrapper, ThemeMode } from "./types"
import { AppTheme } from "@customTypes/themes"

export interface ThemeProviderProps {
  appTheme?: AppTheme
  fallbackTheme: AppTheme
  children: any
  Head: HeadWrapper
}

export const ThemeProvider = ({
  children,
  fallbackTheme,
  appTheme,
  Head,
}: ThemeProviderProps) => {
  const theme = appTheme ?? fallbackTheme
  const [mode, setMode] = React.useState<ThemeMode>("light")
  return (
    <AppThemeContext.Provider
      value={{
        fallbackTheme,
        theme: appTheme,
        mode,
        setMode,
      }}
    >
      {theme && (
        <>
          <ThemeMetaImport theme={theme} Head={Head} />
          <ThemeFontsImport theme={theme} Head={Head} />
          <ThemeStyles theme={theme} />
        </>
      )}
      <>{children}</>
    </AppThemeContext.Provider>
  )
}
