import { BackendApi, backendApiClient } from "@api/index"
import { AuthService } from "@modules/auth/services"
import { getContext } from "redux-saga/effects"
import { AppLocalStorage, IStore } from "./store"

export interface SagaContext {
  store: IStore
  api: BackendApi
  auth: AuthService
}

export const appStateStorage = new AppLocalStorage()
export const authService = new AuthService()

export const sagaContext: SagaContext = {
  store: appStateStorage,
  api: backendApiClient,
  auth: authService,
}

export function* getSagaContext<T extends keyof SagaContext>(
  key: T
): Generator<any, SagaContext[T], any> {
  return yield getContext(key)
}
