import "tailwindcss/tailwind.css"
import "../../styles/tailwind.css"
import "../../styles/globals.css"
import { NextPageWithLayout } from "next"

import type { AppProps } from "next/app"
import { StoreProvider } from "@modules/store"
import { AppRootProviders } from "@root/providers"
import { i18nInitialize } from "src/i18n"

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

i18nInitialize()

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  return (
    <StoreProvider>
      <AppRootProviders>
        {getLayout(<Component {...pageProps} />)}
      </AppRootProviders>
    </StoreProvider>
  )
}

export default CustomApp
