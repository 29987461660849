/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomerDocumentStatus {
    SIGNED = 'Signed',
    MISSING = 'Missing',
    DISABLED = 'Disabled',
    FOLDER_CREATED = 'FolderCreated',
}
