// source: https://css-tricks.com/converting-color-spaces-in-javascript/

import {
  HexaColor,
  HexColor,
  HslaColor,
  HslColor,
  RgbaColor,
  RgbColor,
} from "./types"

function hueShift(h: number, s: number) {
  return (h + s) % 360
}

const hexToInt = (hex: string) => parseInt("0x" + hex)

export function hexToRgb(hexInput: HexColor, isPct = false) {
  let r = hexToInt(hexInput.rHex)
  let g = hexToInt(hexInput.gHex)
  let b = hexToInt(hexInput.bHex)

  if (isPct) {
    r = parseFloat(((r / 255) * 100).toFixed(2))
    g = parseFloat(((g / 255) * 100).toFixed(2))
    b = parseFloat(((b / 255) * 100).toFixed(2))
  }

  return {
    r,
    g,
    b,
  }
}

export function hexAToRgba(hexInput: HexaColor, isPct = false) {
  let r = hexToInt(hexInput.rHex)
  let g = hexToInt(hexInput.gHex)
  let b = hexToInt(hexInput.bHex)
  let a = hexToInt(hexInput.aHex)

  a = parseFloat((a / 255).toFixed(3))

  if (isPct) {
    r = parseFloat(((r / 255) * 100).toFixed(2))
    g = parseFloat(((g / 255) * 100).toFixed(2))
    b = parseFloat(((b / 255) * 100).toFixed(2))
    a = parseFloat((a * 100).toFixed(2))
  }

  return {
    r,
    g,
    b,
    a,
  }
}

export function rgbToHsl(rgbColor: RgbColor): HslColor {
  // make r, g, and b fractions of 1
  const r = rgbColor.r / 255
  const g = rgbColor.g / 255
  const b = rgbColor.b / 255
  // find greatest and smallest channel values
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  // calculate hue
  // no difference
  if (delta == 0) h = 0
  // red is max
  else if (cmax == r) h = ((g - b) / delta) % 6
  // green is max
  else if (cmax == g) h = (b - r) / delta + 2
  // blue is max
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  // make negative hues positive behind 360°
  if (h < 0) h += 360

  // calculate lightness
  l = (cmax + cmin) / 2

  // calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // multiply l and s by 100
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return {
    h,
    s,
    l,
  }
}

export function rgbaToHsla(rgbaColor: RgbaColor): HslaColor {
  // make r, g, and b fractions of 1
  const r = rgbaColor.r / 255
  const g = rgbaColor.g / 255
  const b = rgbaColor.b / 255
  const a = rgbaColor.a

  // find greatest and smallest channel values
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  // calculate hue
  // no difference
  if (delta == 0) h = 0
  // red is max
  else if (cmax == r) h = ((g - b) / delta) % 6
  // green is max
  else if (cmax == g) h = (b - r) / delta + 2
  // blue is max
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  // make negative hues positive behind 360°
  if (h < 0) h += 360

  // calculate lightness
  l = (cmax + cmin) / 2

  // calculate saturation
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // multiply l and s by 100
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  return {
    h,
    s,
    l,
    a,
  }
}

export const calculateHslComplementary = (hsl: HslColor): HslColor => {
  return {
    h: hueShift(hsl.h, 180),
    l: hsl.l,
    s: hsl.s,
  }
}
