/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAttachmentDto } from '../models/CustomerAttachmentDto';
import type { FolderCreateRequest } from '../models/FolderCreateRequest';
import type { FolderCreateResponse } from '../models/FolderCreateResponse';
import type { FolderDto } from '../models/FolderDto';
import type { FolderNotifyParams } from '../models/FolderNotifyParams';
import type { FolderSendParams } from '../models/FolderSendParams';
import type { FoldersSearchRequest } from '../models/FoldersSearchRequest';
import type { FoldersSearchResponse } from '../models/FoldersSearchResponse';
import type { FolderUpdateRequest } from '../models/FolderUpdateRequest';
import type { FolderUpdateResponse } from '../models/FolderUpdateResponse';
import type { SignFolderVerifyRequest } from '../models/SignFolderVerifyRequest';
import type { SignFolderVerifyResponse } from '../models/SignFolderVerifyResponse';
import type { SingFolderInitializeRequest } from '../models/SingFolderInitializeRequest';
import type { SingFolderInitializeResponse } from '../models/SingFolderInitializeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FoldersClient {

    /**
     * @param requestBody
     * @returns FoldersSearchResponse Success
     * @throws ApiError
     */
    public static searchFolders(
        requestBody?: FoldersSearchRequest,
    ): CancelablePromise<FoldersSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param folderId
     * @returns FolderDto Success
     * @throws ApiError
     */
    public static getFolder(
        folderId: string,
    ): CancelablePromise<FolderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/folders/{folderId}',
            path: {
                'folderId': folderId,
            },
        });
    }

    /**
     * @param folderId
     * @returns FolderDto Success
     * @throws ApiError
     */
    public static getFolderForEdit(
        folderId: string,
    ): CancelablePromise<FolderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/folders/getForEdit/{folderId}',
            path: {
                'folderId': folderId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FolderCreateResponse Success
     * @throws ApiError
     */
    public static createFolder(
        requestBody?: FolderCreateRequest,
    ): CancelablePromise<FolderCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns FolderUpdateResponse Success
     * @throws ApiError
     */
    public static updateFolder(
        requestBody?: FolderUpdateRequest,
    ): CancelablePromise<FolderUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param folderId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteFolder(
        folderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/folders/delete/{folderId}',
            path: {
                'folderId': folderId,
            },
        });
    }

    /**
     * @param formData
     * @returns CustomerAttachmentDto Success
     * @throws ApiError
     */
    public static uploadAttachment(
        formData?: {
            ContentType?: string;
            ContentDisposition?: string;
            Headers?: Record<string, Array<string>>;
            Length?: number;
            Name?: string;
            FileName?: string;
            documentType?: string;
            customerId?: string;
        },
    ): CancelablePromise<CustomerAttachmentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/attachment-upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param folderId
     * @param requestBody
     * @returns SingFolderInitializeResponse Success
     * @throws ApiError
     */
    public static signFolderInitialize(
        folderId: string,
        requestBody?: SingFolderInitializeRequest,
    ): CancelablePromise<SingFolderInitializeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/signInitialize/{folderId}',
            path: {
                'folderId': folderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns SignFolderVerifyResponse Success
     * @throws ApiError
     */
    public static signFolderVerify(
        requestBody?: SignFolderVerifyRequest,
    ): CancelablePromise<SignFolderVerifyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/signVerify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param folderId
     * @returns any Success
     * @throws ApiError
     */
    public static signFolderCancel(
        folderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/signCancel/{folderId}',
            path: {
                'folderId': folderId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static folderSend(
        requestBody?: FolderSendParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/sendFolder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static folderNotify(
        requestBody?: FolderNotifyParams,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/folders/notifyFolder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
