import React from "react"
import { Provider } from "react-redux"

import store from "./redux"

type Props = {
  children: React.ReactNode
}

const StoreProvider = (props: Props) => {
  return <Provider store={store}>{props.children}</Provider>
}
export { StoreProvider }
