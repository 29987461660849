import {
  writeObject,
  readObject,
  removeObject,
  clearObjects,
} from "@utils/storage"

export interface IStore {
  save<T>(key: string, value: T): Promise<void>
  get<T>(key: string): Promise<T | undefined>
  delete(key: string): Promise<void>
  clear(): Promise<void>
}

export class AppLocalStorage implements IStore {
  async save<T>(key: string, value: T): Promise<void> {
    writeObject(key, value)
  }
  async get<T>(key: string): Promise<T | undefined> {
    return readObject<T>(key)
  }
  async delete(key: string): Promise<void> {
    removeObject(key)
  }
  async clear(): Promise<void> {
    clearObjects()
  }
}

export const appStateStorage = new AppLocalStorage()
