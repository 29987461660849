/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SellerCreateRequest } from '../models/SellerCreateRequest';
import type { SellerCreateResponse } from '../models/SellerCreateResponse';
import type { SellerDto } from '../models/SellerDto';
import type { SellersSearchRequest } from '../models/SellersSearchRequest';
import type { SellersSearchResponse } from '../models/SellersSearchResponse';
import type { SellerUpdateRequest } from '../models/SellerUpdateRequest';
import type { SellerUpdateResponse } from '../models/SellerUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SellersClient {

    /**
     * @param sellerId
     * @returns SellerDto Success
     * @throws ApiError
     */
    public static getSeller(
        sellerId: string,
    ): CancelablePromise<SellerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/sellers/{sellerId}',
            path: {
                'sellerId': sellerId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param sellerId
     * @returns void
     * @throws ApiError
     */
    public static sellerDelete(
        sellerId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/sellers/{sellerId}',
            path: {
                'sellerId': sellerId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SellersSearchResponse Success
     * @throws ApiError
     */
    public static searchSellers(
        requestBody?: SellersSearchRequest,
    ): CancelablePromise<SellersSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/sellers/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SellerCreateResponse Success
     * @throws ApiError
     */
    public static sellerCreate(
        requestBody?: SellerCreateRequest,
    ): CancelablePromise<SellerCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/sellers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SellerUpdateResponse Success
     * @throws ApiError
     */
    public static sellerUpdate(
        requestBody?: SellerUpdateRequest,
    ): CancelablePromise<SellerUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/sellers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
