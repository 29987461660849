import React, { useEffect } from "react"
import { newUuid } from "../utils/uid"

export interface UseApiInput<TResult, TInput> {
  apiCall: (variables: TInput) => Promise<TResult>
  onSuccess?: (result: TResult) => void
  onError?: (error: Error) => void
  onFinalized?: () => void
  autoTrigger?: boolean
}

export interface ApiState<TResult> {
  operationId?: string
  loading: boolean
  completed: boolean
  called: boolean
  success?: boolean
  data?: TResult
  error?: any
}

const invokeOperation = async <TResult, TInput>(
  input: UseApiInput<TResult, TInput>,
  variables?: TInput
): Promise<TResult> => {
  if (variables) {
    return await (input.apiCall as (variables: TInput) => Promise<TResult>)(
      variables
    )
  }
  return await (input.apiCall as () => Promise<TResult>)()
}

export const useApiLazy = <TResult, TInput>(
  input: UseApiInput<TResult, TInput>
) => {
  const [state, setState] = React.useState<ApiState<TResult>>({
    called: false,
    loading: false,
    completed: false,
  })

  const invoke = async (variables?: TInput): Promise<TResult | undefined> => {
    const operationId = newUuid()
    setState({
      called: true,
      loading: true,
      completed: false,
      data: undefined,
      error: undefined,
      operationId,
    })
    try {
      const result = await invokeOperation(input, variables)
      setState({
        called: true,
        loading: false,
        data: result,
        error: undefined,
        operationId,
        completed: true,
        success: true,
      })
      input.onSuccess?.(result)
      return result
    } catch (e: any) {
      console.error("Error invoking API", e)
      setState({
        called: true,
        loading: false,
        data: undefined,
        error: e,
        operationId,
        completed: true,
        success: false,
      })
      input.onError?.(e)
      throw e
    } finally {
      input.onFinalized?.()
    }
  }

  useEffect(() => {
    if (input.autoTrigger) {
      invoke()
    }
  }, [])

  return {
    invoke,
    state,
  }
}

export const useApi = <TResult, TInput>(
  input: UseApiInput<TResult, TInput>
) => {
  const { invoke, state } = useApiLazy(input)

  React.useEffect(() => {
    invoke()
  }, [])

  return state
}
