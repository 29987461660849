/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FolderSigningOutcome {
    SUCCESS = 'Success',
    ERROR = 'Error',
    IN_PROGRESS = 'InProgress',
    EXPIRED = 'Expired',
    CANCELED = 'Canceled',
}
