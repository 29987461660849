import { useTranslations } from "@modules/translations"
import Button from "@ui/atoms/Button"
import Typography from "@ui/atoms/Typography"
import clsx from "clsx"
import React from "react"

export interface ErrorMessageProps {
  onRetry: () => void
  fullScreen?: boolean
}

const ErrorMessage = ({ onRetry, fullScreen }: ErrorMessageProps) => {
  const { t } = useTranslations("common")
  return (
    <div
      className={clsx(
        "grid justify-center place-content-center w-full h-full",
        {
          "h-screen": fullScreen,
        }
      )}
    >
      <Typography>{t("errors.genericError")}</Typography>
      <Button color="primary" onClick={onRetry}>
        {t("errors.retry")}
      </Button>
    </div>
  )
}

export default ErrorMessage
