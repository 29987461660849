import { AppTheme } from "@customTypes/themes"
import React from "react"
import { HeadWrapper } from "../types"

export interface ThemeMetaImportProps {
  Head: HeadWrapper
  theme: AppTheme
}

export const ThemeMetaImport = ({ theme, Head }: ThemeMetaImportProps) => {
  return (
    <Head>
      <link rel="icon" href={theme.brand.images.icon.primary.src} />
    </Head>
  )
}
