import { useRouter } from "next/router"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

export const useTranslations = (key?: string) => {
  const { t, i18n } = useTranslation(key)

  const tPluralized = (
    {
      singular,
      plural,
    }: {
      singular: string
      plural: string
    },
    count: number,
    options?: any
  ) => {
    return count === 1 ? t(singular as any, options) : t(plural as any, options)
  }

  const changeLanguage = (lang: string) => i18n.changeLanguage(lang)

  return {
    t,
    tPluralized,
    changeLanguage,
  }
}

export const useLocalizedMemo = <T>(factory: () => T) => {
  const { locale } = useRouter()
  return useMemo(factory, [locale])
}
