/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CervedCompanyData } from '../models/CervedCompanyData';
import type { CustomerAccessUpdateRequest } from '../models/CustomerAccessUpdateRequest';
import type { CustomerAccessUpdateResponse } from '../models/CustomerAccessUpdateResponse';
import type { CustomerCompanyUpdateRequest } from '../models/CustomerCompanyUpdateRequest';
import type { CustomerCompanyUpdateResponse } from '../models/CustomerCompanyUpdateResponse';
import type { CustomerCreateRequest } from '../models/CustomerCreateRequest';
import type { CustomerCreateResponse } from '../models/CustomerCreateResponse';
import type { CustomerDocumentsPreferenceUpdateRequest } from '../models/CustomerDocumentsPreferenceUpdateRequest';
import type { CustomerDto } from '../models/CustomerDto';
import type { CustomerSearchRequest } from '../models/CustomerSearchRequest';
import type { CustomerSearchResponse } from '../models/CustomerSearchResponse';
import type { CustomerSellerUpdateRequest } from '../models/CustomerSellerUpdateRequest';
import type { CustomersImportRequest } from '../models/CustomersImportRequest';
import type { CustomersImportResponse } from '../models/CustomersImportResponse';
import type { CustomerTemporaryPasswordResetRequest } from '../models/CustomerTemporaryPasswordResetRequest';
import type { CustomerTemporaryPasswordResetResponse } from '../models/CustomerTemporaryPasswordResetResponse';
import type { SearchCustomerDataResponse } from '../models/SearchCustomerDataResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersClient {

    /**
     * @param requestBody
     * @returns CustomersImportResponse Success
     * @throws ApiError
     */
    public static customersImport(
        requestBody?: CustomersImportRequest,
    ): CancelablePromise<CustomersImportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CustomerSearchResponse Success
     * @throws ApiError
     */
    public static searchCustomers(
        requestBody?: CustomerSearchRequest,
    ): CancelablePromise<CustomerSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param customerId
     * @returns CustomerDto Success
     * @throws ApiError
     */
    public static getCustomer(
        customerId: string,
    ): CancelablePromise<CustomerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/{customerId}',
            path: {
                'customerId': customerId,
            },
        });
    }

    /**
     * @param customerId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCustomer(
        customerId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/{customerId}',
            path: {
                'customerId': customerId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CustomerCreateResponse Success
     * @throws ApiError
     */
    public static createCustomer(
        requestBody?: CustomerCreateRequest,
    ): CancelablePromise<CustomerCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CustomerCompanyUpdateResponse Success
     * @throws ApiError
     */
    public static updateCustomerCompany(
        requestBody?: CustomerCompanyUpdateRequest,
    ): CancelablePromise<CustomerCompanyUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CustomerAccessUpdateResponse Success
     * @throws ApiError
     */
    public static updateCustomerAccess(
        requestBody?: CustomerAccessUpdateRequest,
    ): CancelablePromise<CustomerAccessUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/access',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns CustomerTemporaryPasswordResetResponse Success
     * @throws ApiError
     */
    public static setCustomerTemporaryPassword(
        requestBody?: CustomerTemporaryPasswordResetRequest,
    ): CancelablePromise<CustomerTemporaryPasswordResetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateSeller(
        requestBody?: CustomerSellerUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/seller',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateDocumentsPreference(
        requestBody?: CustomerDocumentsPreferenceUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/documents-preference',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param text
     * @returns SearchCustomerDataResponse Success
     * @throws ApiError
     */
    public static searchCompanyData(
        text: string,
    ): CancelablePromise<SearchCustomerDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/searchCompanyData/{text}',
            path: {
                'text': text,
            },
        });
    }

    /**
     * @param vatNumber
     * @returns CervedCompanyData Success
     * @throws ApiError
     */
    public static getCompanyData(
        vatNumber: string,
    ): CancelablePromise<CervedCompanyData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/getCompanyData/{vatNumber}',
            path: {
                'vatNumber': vatNumber,
            },
        });
    }

}
