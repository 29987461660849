import { DocumentState } from "@api/backend"
import { backendApiClient } from "@api/index"
import { useApiLazy } from "@infrastructure/api"
import { useAppSelector } from "@modules/store/redux"
import { useTranslations } from "@modules/translations"
import { showErrorAlert } from "@ui/notifications/toast"
import { useEffect } from "react"
import { DocumentTemplatesContext } from "./context"

export interface TemplatesProviderProps {
  children: any
}

export const TemplatesProvider = ({ children }: TemplatesProviderProps) => {
  const { t } = useTranslations("common")
  const {
    invoke,
    state: { data, completed },
  } = useApiLazy({
    apiCall: () => backendApiClient.templates.getTemplates(),
    onError: () => showErrorAlert(t("documentTemplates.fetchError")),
    onSuccess: (result) => {
      if (result.length === 0) {
        showErrorAlert(t("documentTemplates.noDocuments"))
      }
    },
  })

  const { authenticated } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (authenticated) {
      invoke()
    }
  }, [authenticated])

  return (
    <DocumentTemplatesContext.Provider
      value={{
        initialized: completed,
        templates: data?.filter((x) => x.state === DocumentState.ACTIVE) ?? [],
        reload: () => invoke(),
      }}
    >
      {children}
    </DocumentTemplatesContext.Provider>
  )
}
