export interface OperationalState<TError> {
  loading: boolean
  success?: boolean
  faulted?: boolean
  error?: TError
}

export interface LoadableState<TData, TError> extends OperationalState<TError> {
  data?: TData
}

export interface LoadableStateWithInput<TData, TInput, TError>
  extends LoadableState<TData, TError> {
  input: TInput | undefined
}

export const empty = <TData, TError>(): LoadableState<TData, TError> => ({
  loading: false,
  faulted: undefined,
  success: undefined,
  error: undefined,
})

export const loading = <TData, TError>(): LoadableState<TData, TError> => ({
  loading: true,
  faulted: undefined,
  success: undefined,
  error: undefined,
})

export const triggered = <TData, TInput, TError>(
  input: TInput
): LoadableStateWithInput<TData, TInput, TError> => ({
  input,
  loading: true,
  faulted: undefined,
  success: undefined,
  error: undefined,
})

export const completed = <TData, TError>(
  data: TData
): LoadableState<TData, TError> => ({
  loading: false,
  success: true,
  faulted: undefined,
  error: undefined,
  data,
})

export const triggerCompleted = <TData, TInput, TError>({
  data,
  input,
}: {
  data: TData
  input: TInput
}): LoadableStateWithInput<TData, TInput, TError> => ({
  ...completed(data),
  input,
})

export const failure = <TData, TError>(
  error?: TError
): LoadableState<TData, TError> => ({
  loading: false,
  success: false,
  faulted: true,
  error,
})

export const triggerFailed = <TData, TInput, TError>({
  error,
  input,
}: {
  error?: TError
  input: TInput
}): LoadableStateWithInput<TData, TInput, TError> => ({
  ...failure(error),
  input,
})

export const idle = <TError>(): OperationalState<TError> => ({
  loading: false,
  success: undefined,
  error: undefined,
  faulted: undefined,
})

export const running = <TError>(): OperationalState<TError> => ({
  loading: true,
  success: undefined,
  error: undefined,
  faulted: undefined,
})

export const success = <TError>(): OperationalState<TError> => ({
  loading: false,
  success: true,
  error: undefined,
  faulted: undefined,
})

export const faulted = <TError>(error?: TError): OperationalState<TError> => ({
  loading: false,
  success: false,
  faulted: true,
  error,
})
