/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRole {
    ADMIN = 'Admin',
    BACKOFFICE = 'Backoffice',
    SELLER = 'Seller',
    USER = 'User',
}
