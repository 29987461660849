import { AppTheme } from "@customTypes/themes"
import { useMemo } from "react"
import { buildThemeVariables } from "../builder"

const ROOT_VARS_LIGHT = `
:root,
:root.light,
:root[data-theme="light"] {
  --color-primary: var(--color-light-primary);
  --color-primary-contrast: var(--color-light-primary-contrast);
  --color-primary-focus: var(--color-light-primary-focus);
  --color-primary-light: var(--color-light-primary-light);

  --color-secondary: var(--color-light-secondary);
  --color-secondary-contrast: var(--color-light-secondary-contrast);
  --color-secondary-focus: var(--color-light-secondary-focus);
  --color-secondary-light: var(--color-light-secondary-light);

  --color-info: var(--color-light-info);
  --color-info-contrast: var(--color-light-info-contrast);
  --color-info-focus: var(--color-light-info-focus);
  --color-info-light: var(--color-light-info-light);

  --color-success: var(--color-light-success);
  --color-success-contrast: var(--color-light-success-contrast);
  --color-success-focus: var(--color-light-success-focus);
  --color-success-light: var(--color-light-success-light);

  --color-error: var(--color-light-error);
  --color-error-contrast: var(--color-light-error-contrast);
  --color-error-focus: var(--color-light-error-focus);
  --color-error-light: var(--color-light-error-light);

  --color-warning: var(--color-light-warning);
  --color-warning-contrast: var(--color-light-warning-contrast);
  --color-warning-focus: var(--color-light-warning-focus);
  --color-warning-light: var(--color-light-warning-light);

  --color-background: var(--color-light-background);
  --color-background-contrast: var(--color-light-background-contrast);
  --color-background-focus: var(--color-light-background-focus);
  --color-background-light: var(--color-light-background-light);

  --color-paper: var(--color-light-paper);
  --color-paper-contrast: var(--color-light-paper-contrast);
  --color-paper-focus: var(--color-light-paper-focus);
  --color-paper-light: var(--color-light-paper-light);

  --color-negative: var(--color-light-negative);
  --color-negative-contrast: var(--color-light-negative-contrast);
  --color-negative-focus: var(--color-light-negative-focus);
  --color-negative-light: var(--color-light-negative-light);

  --color-border: var(--color-light-border);
  --color-border-contrast: var(--color-light-border-contrast);
  --color-border-focus: var(--color-light-border-focus);
  --color-border-light: var(--color-light-border-light);

  --color-grey: var(--color-light-grey);
  --color-grey-medium: var(--color-light-grey-medium);

  --color-sidebar-background: var(--color-light-sidebar-background);
  --color-sidebar-content: var(--color-light-sidebar-content);

  --color-text-primary: var(--color-light-primary);
  --color-text-secondary: var(--color-light-secondary);
  --color-text-info: var(--color-light-info);
  --color-text-warning: var(--color-light-warning);
  --color-text-error: var(--color-light-error);

  --color-text-default: var(--color-light-text-default);
  --color-text-default-contrast: var(--color-light-text-default-contrast);
  --color-text-caption1: var(--color-light-text-caption);
  --color-text-caption2: var(--color-light-text-caption);
}
`

const DAISY_UI_VARS_LIGHT = `
:root,
:root.light,
:root[data-theme="light"] {
  --n: var(--du-light-n);
  --nc: var(--du-light-nc);
  --nf: var(--du-light-nf);

  --a: var(--du-light-a);
  --ac: var(--du-light-ac);
  --af: var(--du-light-af);

  --p: var(--du-light-p);
  --pc: var(--du-light-pc);
  --pf: var(--du-light-pf);

  --s: var(--du-light-s);
  --sc: var(--du-light-sc);
  --sf: var(--du-light-sf);

  --in: var(--du-light-in);
  --inc: var(--du-light-inc);

  --su: var(--du-light-su);
  --suc: var(--du-light-suc);

  --wa: var(--du-light-wa);
  --wac: var(--du-light-wac);

  --er: var(--du-light-er);
  --erc: var(--du-light-erc);

  --b1: var(--du-light-b1);
  --b2: var(--du-light-b2);
  --b3: var(--du-light-b3);
  --bc: var(--du-light-bc);

  --b1: 0 0% 100%;
  --b2: 0 0% 95%;
  --b3: 180 2% 90%;
  --bc: 215 28% 17%;
}
`

const ROOT_VARS_DARK = `
:root.dark,
:root[data-theme="dark"] {
  --color-primary: var(--color-dark-primary);
  --color-primary-contrast: var(--color-dark-primary-contrast);
  --color-primary-focus: var(--color-dark-primary-focus);
  --color-primary-light: var(--color-dark-primary-light);

  --color-secondary: var(--color-dark-secondary);
  --color-secondary-contrast: var(--color-dark-secondary-contrast);
  --color-secondary-focus: var(--color-dark-secondary-focus);
  --color-secondary-light: var(--color-dark-secondary-light);

  --color-info: var(--color-dark-info);
  --color-info-contrast: var(--color-dark-info-contrast);
  --color-info-focus: var(--color-dark-info-focus);
  --color-info-light: var(--color-dark-info-light);

  --color-success: var(--color-dark-success);
  --color-success-contrast: var(--color-dark-success-contrast);
  --color-success-focus: var(--color-dark-success-focus);
  --color-success-light: var(--color-dark-success-light);

  --color-error: var(--color-dark-error);
  --color-error-contrast: var(--color-dark-error-contrast);
  --color-error-focus: var(--color-dark-error-focus);
  --color-error-light: var(--color-dark-error-light);

  --color-warning: var(--color-dark-warning);
  --color-warning-contrast: var(--color-dark-warning-contrast);
  --color-warning-focus: var(--color-dark-warning-focus);
  --color-warning-light: var(--color-dark-warning-light);

  --color-background: var(--color-dark-background);
  --color-background-contrast: var(--color-dark-background-contrast);
  --color-background-focus: var(--color-dark-background-focus);
  --color-background-light: var(--color-dark-background-light);

  --color-paper: var(--color-dark-paper);
  --color-paper-contrast: var(--color-dark-paper-contrast);
  --color-paper-focus: var(--color-dark-paper-focus);
  --color-paper-light: var(--color-dark-paper-light);

  --color-negative: var(--color-dark-negative);
  --color-negative-contrast: var(--color-dark-negative-contrast);
  --color-negative-focus: var(--color-dark-negative-focus);
  --color-negative-light: var(--color-dark-negative-light);

  --color-border: var(--color-dark-border);
  --color-border-contrast: var(--color-dark-border-contrast);
  --color-border-focus: var(--color-dark-border-focus);
  --color-border-light: var(--color-dark-border-light);

  --color-grey: var(--color-dark-grey);
  --color-grey-medium: var(--color-dark-grey-medium);

  --color-sidebar-background: var(--color-dark-sidebar-background);
  --color-sidebar-content: var(--color-dark-sidebar-content);

  --color-text-primary: var(--color-dark-primary);
  --color-text-secondary: var(--color-dark-secondary);
  --color-text-info: var(--color-dark-info);
  --color-text-warning: var(--color-dark-warning);
  --color-text-error: var(--color-dark-error);

  --color-text-default: var(--color-dark-text-default);
  --color-text-default-contrast: var(--color-dark-text-default-contrast);
  --color-text-caption1: var(--color-dark-text-caption);
  --color-text-caption2: var(--color-dark-text-caption);
}
`

const DAISY_UI_VARS_DARK = `
:root.dark,
:root[data-theme="dark"] {
  --n: var(--du-dark-n);
  --nc: var(--du-dark-nc);
  --nf: var(--du-dark-nf);

  --a: var(--du-dark-a);
  --ac: var(--du-dark-ac);
  --af: var(--du-dark-af);

  --p: var(--du-dark-p);
  --pc: var(--du-dark-pc);
  --pf: var(--du-dark-pf);

  --s: var(--du-dark-s);
  --sc: var(--du-dark-sc);
  --sf: var(--du-dark-sf);

  --in: var(--du-dark-in);
  --inc: var(--du-dark-inc);

  --su: var(--du-dark-su);
  --suc: var(--du-dark-suc);

  --wa: var(--du-dark-wa);
  --wac: var(--du-dark-wac);

  --er: var(--du-dark-er);
  --erc: var(--du-dark-erc);

  --b1: var(--du-dark-b1);
  --b2: var(--du-dark-b2);
  --b3: var(--du-dark-b3);
  --bc: var(--du-dark-bc);

  --b1: 220 18% 20%;
  --b2: 220 17% 17%;
  --b3: 219 18% 15%;
  --bc: 220 13% 69%;
}
`

const COMMON_THEME = `
body {
  background-color: var(--color-background);
}
`

export interface ThemeProviderProps {
  theme: AppTheme
  excludeBaseStyles?: boolean
}

const ThemeStyles = ({ theme, excludeBaseStyles }: ThemeProviderProps) => {
  const variables = useMemo(() => buildThemeVariables(theme), [theme])
  return (
    // @ts-ignore
    <style jsx global>
      {`
        :root {
          ${Object.keys(variables)
            .map((key) => `${key}: ${variables[key]};`)
            .join("\n")}
        }

        ${ROOT_VARS_LIGHT}
        ${DAISY_UI_VARS_LIGHT}

        ${ROOT_VARS_DARK}
        ${DAISY_UI_VARS_DARK}

        ${excludeBaseStyles !== true && COMMON_THEME}

        .root {
        }
      `}
    </style>
  )
}

export default ThemeStyles
