import { trimEnd } from "@utils/strings"
import { useRouter } from "next/router"

const PUBLIC_ROUTES = [
  "/auth/forgot-password",
  "/auth/account-verify",
  "/auth/password-reset",
  "/auth/login",
  "/sso-login-callback",
]

export const useIsPublicPage = () => {
  const { pathname } = useRouter()
  return PUBLIC_ROUTES.includes(trimEnd(pathname, "/").toLowerCase())
}
