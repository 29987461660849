import { authName, authReducer } from "@modules/auth/state"
import { controlsName, controlsReducer } from "@modules/controls/state"
import { uiName, uiReducer } from "@modules/ui/state"
import { combineReducers } from "@reduxjs/toolkit"

export const rootReducer = combineReducers({
  [authName]: authReducer,
  [uiName]: uiReducer,
  [controlsName]: controlsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
