import { backendApiClient } from "@api/index"
import { useApiLazy } from "@infrastructure/api"
import { useAppSelector } from "@modules/store/redux"
import { useTranslations } from "@modules/translations"
import { showErrorAlert } from "@ui/notifications/toast"
import { useEffect } from "react"
import { CustomersContext } from "./context"

export interface CustomersProviderProps {
  children: any
}

export const CustomersProvider = ({ children }: CustomersProviderProps) => {
  const { t } = useTranslations("common")
  const {
    invoke,
    state: { data, completed },
  } = useApiLazy({
    apiCall: () => backendApiClient.customers.searchCustomers({}),
    onError: () => showErrorAlert(t("customers.fetchError")),
  })

  const { authenticated } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (authenticated) {
      invoke()
    }
  }, [authenticated])

  return (
    <CustomersContext.Provider
      value={{
        initialized: completed,
        customers: data?.items ?? [],
        reload: () => invoke(),
      }}
    >
      {children}
    </CustomersContext.Provider>
  )
}
