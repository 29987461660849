import { useAppSelector } from "@modules/store/redux"

export const useUser = () => {
  const { userRole } = useAppSelector((state) => state.auth)
  return {
    role: userRole,
    isAdmin: userRole === "admin",
    isCustomer: userRole === "customer",
    isSeller: userRole === "seller",
    isBackoffice: userRole === "backoffice",
  }
}
