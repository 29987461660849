/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SellersSortBy {
    CODE = 'Code',
    NAME = 'Name',
    CREATED = 'Created',
    UPDATED = 'Updated',
}
