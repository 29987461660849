/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetFileDownloadUrlResponse } from '../models/GetFileDownloadUrlResponse';
import type { Stream } from '../models/Stream';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesClient {

    /**
     * @param fileId
     * @returns Stream Success
     * @throws ApiError
     */
    public static download(
        fileId: string,
    ): CancelablePromise<Stream> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/files/download/{fileId}',
            path: {
                'fileId': fileId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param fileId
     * @returns GetFileDownloadUrlResponse Success
     * @throws ApiError
     */
    public static getDownloadUrl(
        fileId: string,
    ): CancelablePromise<GetFileDownloadUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/files/getDownloadUrl/{fileId}',
            path: {
                'fileId': fileId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
