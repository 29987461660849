import React from "react"

export interface CustomContainerProps {
  children: any
  fluid?: boolean
}

const getContainerClass = (props: CustomContainerProps) => {
  if (props.fluid) {
    return ""
  }

  return "container"
}

export const Container = (props: CustomContainerProps) => {
  return <div className={getContainerClass(props)}>{props.children}</div>
}
