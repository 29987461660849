import { call, put } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { PasswordResetInput } from "../state/types"
import { authActions } from "../state"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { extractUserProfile, extractUserRole } from "../converters/user"

export function* passwordResetSaga(action: PayloadAction<PasswordResetInput>) {
  try {
    const api = yield* getSagaContext("api")
    const result = yield* call(api.auth.resetPassword, {
      newPassword: action.payload.newPassword,
      uid: action.payload.uid,
      resetToken: action.payload.resetToken,
    })

    if (result.error) {
      yield* put(authActions.passwordResetFailure(result.error))
      return
    }

    yield* call(api.setToken, result.authToken!)
    const profile = yield* call(api.auth.profile)
    yield* put(
      authActions.setAuthenticated({
        token: result.authToken!,
        profile: extractUserProfile(profile),
        userRole: extractUserRole(profile),
      })
    )

    yield* put(authActions.passwordResetSuccess())
  } catch (error) {
    yield* put(authActions.passwordResetFailure())
  }
}
