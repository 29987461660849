import { makeAbsolute } from "@root/routing/paths"
import { buildUrl } from "@utils/url"

export const dashboardPagePath = () => "/dashboard/"
export const loginPagePath = (redirectUrl: string) =>
  buildUrl("/auth/login/", {
    redirectUrl,
  })

export const unauthorizedPagePath = () => "/unauthorized/"
export const resetPasswordUrl = (email: string) =>
  buildUrl("/auth/forgot-password/", {
    email,
  })

export const adLoginCallbackPagePath = () => "/sso-login-callback/"

export const profilePagePath = () => "/profile/"
export const reportPagePath = () => "/report/"
export const customersPagePath = () => "/customers/"
export const emailLogsPagePath = () => "/email-logs/"
export const foldersPagePath = () => "/folders/"
export const sellersPagePath = () => "/sellers/"
export const documentsPagePath = () => "/templates/"
export const adminPagePath = () => "/admin/"
export const temporaryPagePath = () => "/temporary-password/"

export const postLoginUrl = () => foldersPagePath()

export const folderVerifyUrl = (input: {
  folderId: string
  intentId: string
}) => `/signing/verify/?folderId=${input.folderId}&intentId=${input.intentId}`

export const absoluteFolderVerifyUrl = (input: {
  folderId: string
  intentId: string
}) => makeAbsolute(folderVerifyUrl(input))

export const passwordResetAbsUrlTemplate = () =>
  makeAbsolute("/auth/password-reset/")

export const signingSuccessRedirectUrlTemplate = () =>
  makeAbsolute("/signing/verify/?folderId={folderId}&intentId={intentId}")

export const signingCancellationRedirectUrlTemplate = () =>
  makeAbsolute("/signing/canceled/?folderId={folderId}&intentId={intentId}")

export const signingErrorRedirectUrlTemplate = () =>
  makeAbsolute("/signing/error/?folderId={folderId}&intentId={intentId}")

export const signingSuccessPath = (input: {
  folderId: string
  intentId: string
}) => `/signing/success/?folderId=${input.folderId}&intentId=${input.intentId}`

export const signingErrorPath = (input: {
  folderId: string
  intentId: string
}) => `/signing/error/?folderId=${input.folderId}&intentId=${input.intentId}`

export const canceledErrorPath = (input: {
  folderId: string
  intentId: string
}) => `/signing/canceled/?folderId=${input.folderId}&intentId=${input.intentId}`

export const folderDetailsUrlTemplate = () =>
  makeAbsolute(foldersPagePath() + "?folderId={folderId}")
