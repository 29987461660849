/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerMassiveSendData } from '../models/CustomerMassiveSendData';
import type { CustomerReportSearchParams } from '../models/CustomerReportSearchParams';
import type { CustomerReportSearchResults } from '../models/CustomerReportSearchResults';
import type { CustomersMassiveSendRequest } from '../models/CustomersMassiveSendRequest';
import type { CustomersReportDto } from '../models/CustomersReportDto';
import type { Stream } from '../models/Stream';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportClient {

    /**
     * @param requestBody
     * @returns CustomerMassiveSendData Success
     * @throws ApiError
     */
    public static getMassiveSendData(
        requestBody?: CustomerReportSearchParams,
    ): CancelablePromise<CustomerMassiveSendData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/massive-send-preview',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static invokeMassiveSend(
        requestBody?: CustomersMassiveSendRequest,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/massive-send-invoke',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CustomerReportSearchResults Success
     * @throws ApiError
     */
    public static getReport(
        requestBody?: CustomerReportSearchParams,
    ): CancelablePromise<CustomerReportSearchResults> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/fetch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param customerId
     * @returns void
     * @throws ApiError
     */
    public static reportCustomerRefresh(
        customerId?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/refresh-customer',
            query: {
                'customerId': customerId,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static reportRefresh(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/refresh-all',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns Stream Success
     * @throws ApiError
     */
    public static downloadReport(): CancelablePromise<Stream> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/report/download',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CustomersReportDto Success
     * @throws ApiError
     */
    public static generateReport(
        requestBody?: CustomerReportSearchParams,
    ): CancelablePromise<CustomersReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/report/generate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
