import {
  ComplexColor,
  ScaledColor,
  SingleColor,
  TextColors,
  SidebarColors,
  ThemeColors,
  ThemePalette,
} from "@customTypes/themes"
import { convertToHsl } from "@modules/colors"
import { HslColor } from "@modules/colors/types"
import Color from "color"
import { ThemeVariablesPrefixes } from "../consts"
import { ThemeVariables } from "./types"

const generateFocusColor = (hsl: HslColor): HslColor => {
  const value = Color(hsl).darken(0.2).hsl().round().array()
  return {
    h: value[0],
    s: value[1],
    l: value[2],
  }
}

const generateContrastColor = (input: HslColor, percentage = 0.8): HslColor => {
  if (Color(input).isDark()) {
    const arr = Color(input, "hsl")
      .mix(Color("white"), percentage)
      .saturate(10)
      .hsl()
      .round()
      .array()
    return {
      h: arr[0],
      s: arr[1],
      l: arr[2],
    }
  }

  const arr = Color(input)
    .mix(Color("black"), percentage)
    .saturate(10)
    .hsl()
    .round()
    .array()
  return {
    h: arr[0],
    s: arr[1],
    l: arr[2],
  }
}

export const calculateThemeColorValues = (color: ComplexColor) => {
  const hsl = convertToHsl(color.default)
  const focus = color.focus
    ? convertToHsl(color.focus)
    : generateFocusColor(hsl)
  const contrast = color.contrast
    ? convertToHsl(color.contrast)
    : generateContrastColor(hsl)

  return {
    original: color,
    hsl: {
      default: hsl,
      focus,
      contrast,
    },
  }
}

const formatHsl = (hsl: HslColor) => `${hsl.h} ${hsl.s}% ${hsl.l}%`

const themeColorVariables = (
  palette: string,
  name: string,
  color: ComplexColor
): { [key: string]: string } => ({
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}`]: color.default,
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}-contrast`]:
    color.contrast ?? "",
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}-focus`]:
    color.focus ?? "",
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}-light`]:
    color.light ?? "",
})

const themeScaledColorVariables = (
  palette: string,
  name: string,
  color: ScaledColor
): { [key: string]: string } => ({
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}`]: color.default,
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}-medium`]: color.medium,
})

const themeSingleColorVariable = (
  palette: string,
  name: string,
  color: SingleColor
): { [key: string]: string } => ({
  [`--${ThemeVariablesPrefixes.Color}-${palette}-${name}`]: color.default,
})

const themeTextColorVariables = (palette: string, colors: TextColors) => ({
  [`--${ThemeVariablesPrefixes.Color}-${palette}-text-default`]: colors.default,
  [`--${ThemeVariablesPrefixes.Color}-${palette}-text-caption`]: colors.caption,
})

const themeSidebarColorValues = (palette: string, colors: SidebarColors) => ({
  [`--${ThemeVariablesPrefixes.Color}-${palette}-sidebar-background`]:
    colors.background,
  [`--${ThemeVariablesPrefixes.Color}-${palette}-sidebar-content`]:
    colors.content,
})

const buildDaisyUiColors = (colors: ThemeColors, palette: string) => {
  const primary = calculateThemeColorValues(colors.primary)
  const secondary = calculateThemeColorValues(colors.secondary)
  const success = calculateThemeColorValues(colors.success)
  const info = calculateThemeColorValues(colors.info)
  const warning = calculateThemeColorValues(colors.warning)
  const error = calculateThemeColorValues(colors.error)
  const paper = calculateThemeColorValues(colors.paper)
  const neutral = calculateThemeColorValues(colors.background)

  return {
    // neutral
    [`--du-${palette}-n`]: formatHsl(neutral.hsl.default),
    [`--du-${palette}-nc`]: formatHsl(neutral.hsl.contrast),
    [`--du-${palette}--nf`]: formatHsl(neutral.hsl.focus),
    // primary
    [`--du-${palette}-p`]: formatHsl(primary.hsl.default),
    [`--du-${palette}-pc`]: formatHsl(primary.hsl.contrast),
    [`--du-${palette}-pf`]: formatHsl(primary.hsl.focus),
    // secondary
    [`--du-${palette}-s`]: formatHsl(secondary.hsl.default),
    [`--du-${palette}-sc`]: formatHsl(secondary.hsl.contrast),
    [`--du-${palette}-sf`]: formatHsl(secondary.hsl.focus),
    // info
    [`--du-${palette}-in`]: formatHsl(info.hsl.default),
    [`--du-${palette}-inc`]: formatHsl(info.hsl.contrast),
    // success
    [`--du-${palette}-su`]: formatHsl(success.hsl.default),
    [`--du-${palette}-suc`]: formatHsl(success.hsl.contrast),
    // warning
    [`--du-${palette}-wa`]: formatHsl(warning.hsl.default),
    [`--du-${palette}-wac`]: formatHsl(warning.hsl.contrast),
    // error
    [`--du-${palette}-er`]: formatHsl(error.hsl.default),
    [`--du-${palette}-erc`]: formatHsl(error.hsl.contrast),
    // base content
    [`--du-${palette}-b1`]: formatHsl(paper.hsl.default),
    [`--du-${palette}-b2`]: formatHsl(paper.hsl.default),
    [`--du-${palette}-b3`]: formatHsl(paper.hsl.default),
    [`--du-${palette}-bc`]: formatHsl(paper.hsl.contrast),
    // accent
    // [`--du-${palette}-a`]: formatHsl(primary.hsl.default),
    // [`--du-${palette}-ac`]: formatHsl(primary.hsl.contrast),
    // [`--du-${palette}-af`]: formatHsl(primary.hsl.focus),
  }
}

const buildTailwindBaseColors = (colors: ThemeColors, palette: string) => {
  return {
    ...themeColorVariables(palette, "primary", colors.primary),
    ...themeColorVariables(palette, "secondary", colors.secondary),
    ...themeColorVariables(palette, "info", colors.info),
    ...themeColorVariables(palette, "success", colors.success),
    ...themeColorVariables(palette, "error", colors.error),
    ...themeColorVariables(palette, "warning", colors.warning),
    ...themeColorVariables(palette, "background", colors.background),
    ...themeColorVariables(palette, "paper", colors.paper),
    ...themeColorVariables(palette, "negative", colors.negative),
    ...themeColorVariables(palette, "border", colors.border),
    ...themeScaledColorVariables(palette, "grey", colors.grey),
    ...themeSidebarColorValues(palette, colors.sidebar),
    ...themeTextColorVariables(palette, colors.text),
  }
}

export const buildThemePaletteVariables = (
  palette: ThemePalette
): ThemeVariables => {
  return {
    // base tailwind colors
    ...buildTailwindBaseColors(palette.light, "light"),
    ...(palette.dark ? buildTailwindBaseColors(palette.dark, "dark") : {}),
    // daisy ui colors
    ...buildDaisyUiColors(palette.light, "light"),
    ...(palette.dark ? buildDaisyUiColors(palette.dark, "dark") : {}),
  }
}
