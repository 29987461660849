import { SellerDto } from "@api/backend"
import { createContext } from "react"

export interface Sellers {
  sellers: SellerDto[]
  initialized: boolean
  reload: () => void
}

export const SellersContext = createContext<Sellers>({
  initialized: false,
  sellers: [],
  reload: () => {},
})
