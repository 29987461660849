/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SigningOperationStatus {
    INITIALIZING = 'Initializing',
    INITIALIZATION_FAILED = 'InitializationFailed',
    IN_PROGRESS = 'InProgress',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled',
    EXPIRED = 'Expired',
    FAULTED = 'Faulted',
}
