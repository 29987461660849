import { OpenAPI } from "@api/backend"
import { useAppSelector } from "@modules/store/redux"
import React from "react"
import { trimEnd } from "../../../utils/strings"

interface Props {
  children: any
}

const ApiProvider = ({ children }: Props) => {
  OpenAPI.BASE = trimEnd(process.env.NEXT_PUBLIC_BACKEND_URL as string, "/")

  const { token } = useAppSelector((state) => state.auth)

  React.useEffect(() => {
    OpenAPI.TOKEN = token ?? undefined
  }, [token])

  return <>{children}</>
}

export default ApiProvider
