import React from "react"
import Spinner from "../Spinner"

export type SpinnerSize = "small" | "default" | "large"
export type SpinnerColor = "primary" | "white"

export interface CustomLoaderProps {
  size?: number
  show?: boolean
  color?: SpinnerColor
}

const Loader = ({ show, color, size }: CustomLoaderProps) => {
  return show !== false ? <Spinner color={color} size={size} /> : <></>
}

Loader.defaultProps = {
  color: "primary",
}

export default Loader
