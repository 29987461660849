/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveDirectoryGetUserAccessTokenRequest } from '../models/ActiveDirectoryGetUserAccessTokenRequest';
import type { ActiveDirectoryGetUserAccessTokenResponse } from '../models/ActiveDirectoryGetUserAccessTokenResponse';
import type { ForgotPasswordResponse } from '../models/ForgotPasswordResponse';
import type { GetActiveDirectoryLoginUrlRequest } from '../models/GetActiveDirectoryLoginUrlRequest';
import type { GetActiveDirectoryLoginUrlResult } from '../models/GetActiveDirectoryLoginUrlResult';
import type { GetUserProviderRequest } from '../models/GetUserProviderRequest';
import type { GetUserProviderResult } from '../models/GetUserProviderResult';
import type { GetUsersRequest } from '../models/GetUsersRequest';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PasswordChangeResponse } from '../models/PasswordChangeResponse';
import type { PasswordForceRequest } from '../models/PasswordForceRequest';
import type { PasswordResetResponse } from '../models/PasswordResetResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { User } from '../models/User';
import type { UserCreateRequest } from '../models/UserCreateRequest';
import type { UserCreateResponse } from '../models/UserCreateResponse';
import type { UserForgotPasswordRequest } from '../models/UserForgotPasswordRequest';
import type { UserLoginRequest } from '../models/UserLoginRequest';
import type { UserLoginResult } from '../models/UserLoginResult';
import type { UserRoleAssignRequest } from '../models/UserRoleAssignRequest';
import type { UserUpdateRequest } from '../models/UserUpdateRequest';
import type { UserUpdateResponse } from '../models/UserUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthClient {

    /**
     * @param requestBody
     * @returns GetUserProviderResult Success
     * @throws ApiError
     */
    public static getUserProvider(
        requestBody?: GetUserProviderRequest,
    ): CancelablePromise<GetUserProviderResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/provider',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns GetActiveDirectoryLoginUrlResult Success
     * @throws ApiError
     */
    public static getActiveDirectoryLoginUrl(
        requestBody?: GetActiveDirectoryLoginUrlRequest,
    ): CancelablePromise<GetActiveDirectoryLoginUrlResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/activeDirectoryLoginUrl',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ActiveDirectoryGetUserAccessTokenResponse Success
     * @throws ApiError
     */
    public static getActiveDirectoryUserAccessToken(
        requestBody?: ActiveDirectoryGetUserAccessTokenRequest,
    ): CancelablePromise<ActiveDirectoryGetUserAccessTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/activeDirectoryUserAccessToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns UserLoginResult Success
     * @throws ApiError
     */
    public static login(
        requestBody?: UserLoginRequest,
    ): CancelablePromise<UserLoginResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ForgotPasswordResponse Success
     * @throws ApiError
     */
    public static forgotPassword(
        requestBody?: UserForgotPasswordRequest,
    ): CancelablePromise<ForgotPasswordResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/forgotPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PasswordResetResponse Success
     * @throws ApiError
     */
    public static resetPassword(
        requestBody?: ResetPasswordRequest,
    ): CancelablePromise<PasswordResetResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/resetPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static profile(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/profile',
        });
    }

    /**
     * @param requestBody
     * @returns PasswordChangeResponse Success
     * @throws ApiError
     */
    public static changePassword(
        requestBody?: PasswordChangeRequest,
    ): CancelablePromise<PasswordChangeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/changePassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User Success
     * @throws ApiError
     */
    public static users(
        requestBody?: GetUsersRequest,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns UserCreateResponse Success
     * @throws ApiError
     */
    public static userCreate(
        requestBody?: UserCreateRequest,
    ): CancelablePromise<UserCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/users/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns UserUpdateResponse Success
     * @throws ApiError
     */
    public static userUpdate(
        userId: string,
        requestBody?: UserUpdateRequest,
    ): CancelablePromise<UserUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static roleAssign(
        userId: string,
        requestBody?: UserRoleAssignRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/users/{userId}/updateRole',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PasswordChangeResponse Success
     * @throws ApiError
     */
    public static forcePassword(
        requestBody?: PasswordForceRequest,
    ): CancelablePromise<PasswordChangeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/forcePassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static authInitialize(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/Initialize',
        });
    }

}
