/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomersSortBy {
    SAP_CODE = 'SapCode',
    NAME = 'Name',
    VAT_NUMBER = 'VatNumber',
    STATUS = 'Status',
    CREATED = 'Created',
    UPDATED = 'Updated',
}
