/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FoldersSortBy {
    SAP_CODE = 'SapCode',
    CUSTOMER = 'Customer',
    VAT_NUMBER = 'VatNumber',
    SELLER = 'Seller',
    STATUS = 'Status',
    CREATED = 'Created',
    UPDATED = 'Updated',
}
