/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TemplateType {
    PERSONAL_DATA = 'PersonalData',
    ETHICAL_DISCLAIMER = 'EthicalDisclaimer',
    PRIVACY = 'Privacy',
    CGV = 'Cgv',
    PALETTE = 'Palette',
    OTHER = 'Other',
}
