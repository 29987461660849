import Loader from "@ui/atoms/Loader"
import SplashScreen from "@ui/organisms/SplashScreen"

const MainSplashScreen = () => {
  return (
    <SplashScreen>
      <div className="flex items-center justify-center">
        {/* <InitialLoader /> */}
        <Loader size={60} />
      </div>
    </SplashScreen>
  )
}

export default MainSplashScreen
