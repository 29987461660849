import { Row } from "@ui/spacings"
import { setCssVar } from "@ui/utils/styles"
import React, { useState } from "react"
import classes from "./SplashScreen.module.css"

export interface SplashScreenProps {
  image?: string
  logoCompany?: string
  logoApp?: string
  children: any
}

const LOGO_APP_IMG = "/logos/heilderberg-head.png"

const splashBgImg = (index: number) => `url(/img/splash-screen-${index}.jpg)`

const SplashScreen = ({
  image,
  logoApp,
  logoCompany,
  children,
}: SplashScreenProps) => {
  const [img] = useState(image ?? splashBgImg(1))

  // useEffect(() => {
  //   if (!img) {
  //     setImg(splashBgImg(Math.floor(Math.random() * 3) + 1))
  //   }
  // }, [])

  return (
    <Row.Container
      noGutters
      h100
      style={{
        ...setCssVar("--bg-image", img ?? ""),
      }}
      className="bg-paper"
    >
      <Row.Col xs={0} md={5} className={classes.bg}></Row.Col>
      <Row.Col xs={12} md={7}>
        <div
          className={`relative h-100 flex justify-center items-center ${classes.formCol}`}
        >
          <div className={`block desk:hidden ${classes.formBgTop}`}></div>
          <div className={classes.form}>
            {(logoCompany || logoApp) && (
              <div className="app flex justify-center mb-[1rem] gap-4">
                {logoCompany && (
                  <div>
                    <img src={logoCompany} width={180} />
                  </div>
                )}
                {logoApp && (
                  <div className="flex justify-center">
                    <img src={logoApp} width={480} />
                  </div>
                )}
              </div>
            )}
            {children}
          </div>
          <div className={`block desk:hidden ${classes.formBgBottom}`}></div>
        </div>
      </Row.Col>
    </Row.Container>
  )
}

SplashScreen.defaultProps = {
  // logoCompany: LOGO_COMPANY_IMG,
  logoApp: LOGO_APP_IMG,
}

export default SplashScreen
