import { AuthProvider } from "@modules/auth"
import { useControlsGlobalFunctions } from "@modules/controls/hooks/useControlsGlobalFunctions"
import { CustomersProvider } from "@modules/documents/providers/Customers/provider"
import { SellersProvider } from "@modules/documents/providers/Sellers/provider"
import { TemplatesProvider } from "@modules/documents/providers/Templates/provider"
import { ErrorsWrapper } from "@modules/errors"
import { ThemeProvider } from "@modules/theme"
import { useLogRocket } from "@modules/tracking/hooks/useLogrocket"
import { useUiInitializer } from "@modules/ui/hooks/useUiInitializer"
import { useUiThemeHandler } from "@modules/ui/hooks/useUiThemeHandler"
import ApiProvider from "@root/api/ApiProvider"
import { ConformationProvider } from "@ui/notifications/confirmation"
import { ToastRoot } from "@ui/notifications/toast"
import { ThemeVariables } from "@ui/theme/variables"
import Head from "next/head"
import React from "react"

export interface AppRootProvidersProps {
  children: any
}

export const AppRootProviders = ({ children }: AppRootProvidersProps) => {
  useLogRocket()
  useUiInitializer()
  useUiThemeHandler()
  useControlsGlobalFunctions()

  console.log("app published - version 0.0.4")

  return (
    <ApiProvider>
      <AuthProvider>
        <TemplatesProvider>
          <SellersProvider>
            <CustomersProvider>
              <ThemeProvider
                appTheme={ThemeVariables}
                fallbackTheme={ThemeVariables}
                Head={Head}
              >
                <ConformationProvider>
                  <ErrorsWrapper fullScreen>{children}</ErrorsWrapper>
                  <ToastRoot />
                </ConformationProvider>
              </ThemeProvider>
            </CustomersProvider>
          </SellersProvider>
        </TemplatesProvider>
      </AuthProvider>
    </ApiProvider>
  )
}
