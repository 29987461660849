import { CustomerDto } from "@api/backend"
import { createContext } from "react"

export interface Customers {
  customers: CustomerDto[]
  initialized: boolean
  reload: () => void
}

export const CustomersContext = createContext<Customers>({
  initialized: false,
  customers: [],
  reload: () => {},
})
