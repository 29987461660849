import { backendApiClient } from "@api/index"
import { useApiLazy } from "@infrastructure/api"
import { useUser } from "@modules/auth/hooks/useUserRole"
import { useAppSelector } from "@modules/store/redux"
import { useTranslations } from "@modules/translations"
import { showErrorAlert } from "@ui/notifications/toast"
import { useEffect } from "react"
import { SellersContext } from "./context"

export interface SellersProviderProps {
  children: any
}

export const SellersProvider = ({ children }: SellersProviderProps) => {
  const { t } = useTranslations("common")
  const { role } = useUser()
  const {
    invoke,
    state: { data, completed },
  } = useApiLazy({
    apiCall: async () => {
      const results = await backendApiClient.sellers.searchSellers({})
      return results.items
    },
    onError: () => showErrorAlert(t("sellers.fetchError")),
    onSuccess: (result) => {
      if (result.length === 0) {
        showErrorAlert(t("sellers.noSellers"))
      }
    },
  })

  const { authenticated } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (authenticated && role !== "customer") {
      invoke()
    }
  }, [authenticated, role])

  return (
    <SellersContext.Provider
      value={{
        initialized: completed,
        sellers: data ?? [],
        reload: () => invoke(),
      }}
    >
      {children}
    </SellersContext.Provider>
  )
}
