import {
  AuthClient,
  CustomersClient,
  EmailClient,
  FilesClient,
  FoldersClient,
  OpenAPI,
  ReportClient,
  SellersClient,
  TemplatesClient,
} from "./backend"

export class BackendApi {
  public auth = AuthClient
  public sellers = SellersClient
  public customers = CustomersClient
  public report = ReportClient
  public folders = FoldersClient
  public templates = TemplatesClient
  public files = FilesClient
  public email = EmailClient

  setToken(token: string) {
    OpenAPI.TOKEN = token
  }

  getToken() {
    return OpenAPI.TOKEN
  }
}

export const backendApiClient = new BackendApi()
