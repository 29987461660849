import { isTokenExpired } from "../utils/jwt"

export class AuthService {
  isTokenExpired(authToken: string, when: Date) {
    return isTokenExpired(authToken, when)
  }

  persistJwt() {
    return process.env.NEXT_PUBLIC_PERSIST_AUTH_TOKEN === "true"
  }
}
