import { getSagaContext } from "@modules/store/redux/sagaContext"
import { PayloadAction } from "@reduxjs/toolkit"
import { ThemeMode } from "@ui/types"
import { call } from "typed-redux-saga"
import { UiStoreKeys } from "../storage"

export function* setThemeMode(action: PayloadAction<ThemeMode>) {
  const storage = yield* getSagaContext("store")
  yield* call(storage.save, UiStoreKeys.ThemeMode, action.payload)
}
