import { takeLatest, fork } from "typed-redux-saga"
import { authActions } from "../state"
import { authInitializeSaga } from "./authInitialize"
import { changePasswordSaga } from "./changePassword"
import { forgotPasswordSaga } from "./forgotPassword"
import { loginSaga } from "./login"
import { logoutSaga } from "./logout"
import { passwordResetSaga } from "./passwordReset"
import { watchTokenSaga } from "./watchToken"
import { ssoLoginCallback } from "./ssoLoginCallback"

export function* authRootSaga() {
  yield* takeLatest(authActions.loginStarted, loginSaga)
  yield* takeLatest(authActions.logoutStarted, logoutSaga)
  yield* takeLatest(authActions.passwordResetStarted, passwordResetSaga)
  yield* takeLatest(authActions.forgotPasswordStarted, forgotPasswordSaga)
  yield* takeLatest(authActions.changePasswordStarted, changePasswordSaga)
  yield* takeLatest(authActions.initializationStart, authInitializeSaga)
  yield* takeLatest(authActions.ssoLoginCallbackStarted, ssoLoginCallback)
  yield* fork(watchTokenSaga)
}
