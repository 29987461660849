import React from "react"
import { ReactNode } from "react"
import { useDispatch } from "react-redux"
import { useIsPublicPage } from "./hooks/useIsPublicPage"
import { useAppSelector } from "@modules/store/redux"
import LogRocket from "logrocket"
import { useRouter } from "next/router"
import { loginPagePath, temporaryPagePath } from "@routing/paths"
import { useIsClient } from "@ui/hooks/useIsClient"
import { authActions } from "./state"
import MainSplashScreen from "@shell/MainSplashScreen"

type Props = {
  children: ReactNode
}

export const AuthProvider = (props: Props) => {
  const { authenticated, profile, initialized, initializing } = useAppSelector(
    (state) => state.auth
  )
  const isPublicPage = useIsPublicPage()
  const dispatch = useDispatch()
  const router = useRouter()
  const isClient = useIsClient()

  React.useEffect(() => {
    if (!initialized && !initializing) {
      dispatch(authActions.initializationStart())
    }
  }, [initialized, initializing])

  React.useEffect(() => {
    if (profile) {
      LogRocket.identify(profile.email, {
        email: profile?.email ?? "",
        name: profile?.lastName ?? "",
      })
    }
  }, [profile])

  React.useEffect(() => {
    if (profile?.temporaryPassword) {
      router.push(temporaryPagePath())
    }
  }, [profile, router.asPath])

  if (!isClient || !initialized) {
    return <MainSplashScreen />
  }

  if (initialized && !authenticated && !isPublicPage) {
    console.log("login provider -> redirect to login page")
    router.push(loginPagePath(router.asPath))
    return <MainSplashScreen />
  }

  return <>{props.children}</>
}
