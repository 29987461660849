/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateCreateRequest } from '../models/TemplateCreateRequest';
import type { TemplateDeleteRequest } from '../models/TemplateDeleteRequest';
import type { TemplateDto } from '../models/TemplateDto';
import type { TemplateUpdateRequest } from '../models/TemplateUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplatesClient {

    /**
     * @returns TemplateDto Success
     * @throws ApiError
     */
    public static getTemplates(): CancelablePromise<Array<TemplateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/templates',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createTemplate(
        requestBody?: TemplateCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static updateTemplate(
        requestBody?: TemplateUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteTemplate(
        requestBody?: TemplateDeleteRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
