import { call, put } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { ForgotPasswordInput } from "../state/types"
import {
  adLoginCallbackPagePath,
  passwordResetAbsUrlTemplate,
} from "@routing/paths"
import { authActions } from "../state"
import { clientAbsoluteUrl } from "@routing/urls"

export function* forgotPasswordSaga(
  action: PayloadAction<ForgotPasswordInput>
) {
  try {
    const api = yield* getSagaContext("api")
    yield* call(api.auth.forgotPassword, {
      email: action.payload.email,
      callbackUrlTemplate: passwordResetAbsUrlTemplate(),
      ssoPostLoginUrl: clientAbsoluteUrl(adLoginCallbackPagePath()),
    })

    yield* put(authActions.forgotPasswordSuccess())
  } catch (error) {
    yield* put(authActions.forgotPasswordFailure())
  }
}
