import { PasswordChangeError, PasswordResetError } from "@api/backend"
import { faulted, idle, running, success } from "@modules/store/states"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthenticationData } from "../types"
import {
  AuthState,
  ChangePasswordInput,
  ForgotPasswordInput,
  LoginFailureInput,
  LoginInfo,
  PasswordResetInput,
  SSOLoginCallbackInput,
  UserProfile,
} from "./types"

const name = "auth"

const initialState: AuthState = {
  authenticated: false,
  loginError: false,
  loginErrorType: null,
  lockoutMinutes: null,
  userRole: null,
  profile: null,
  isLoading: false,
  initialized: false,
  initializing: false,
  token: null,
}

const slice = createSlice({
  name,
  reducers: {
    initializationStart(state) {
      state.initialized = false
      state.initializing = true
    },
    initializationCompleted(state) {
      console.log("initializationCompleted")
      state.initialized = true
      state.initializing = true
    },

    loginStarted(state, payloadAction: PayloadAction<LoginInfo>) {
      return {
        authenticated: false,
        initialized: true,
        initializing: false,
        isLoading: true,
        loginError: false,
        loginErrorType: null,
        lockoutMinutes: null,
        profile: null,
        token: null,
        userRole: null,
      }
    },
    loginSuccess(state) {
      return {
        ...state,
        authenticated: true,
        initialized: true,
        initializing: false,
        isLoading: false,
        loginError: false,
      }
    },
    loginFailure(state, action: PayloadAction<LoginFailureInput | undefined>) {
      return {
        authenticated: false,
        initialized: true,
        initializing: false,
        isLoading: false,
        loginError: true,
        loginErrorType: action.payload?.error ?? null,
        lockoutMinutes: action.payload?.lockoutMinutes ?? null,
        profile: null,
        token: null,
        userRole: null,
      }
    },
    loginClear(state) {
      state.loginError = false
    },
    logoutStarted(state) {},

    passwordResetStarted(
      state,
      payloadAction: PayloadAction<PasswordResetInput>
    ) {
      state.passwordReset = running()
    },
    passwordResetSuccess(state) {
      state.passwordReset = success()
    },
    passwordResetFailure(
      state,
      action: PayloadAction<PasswordResetError | undefined>
    ) {
      state.passwordReset = faulted(action.payload)
    },
    passwordResetClear(state) {
      state.passwordReset = idle()
    },

    forgotPasswordStarted(
      state,
      payloadAction: PayloadAction<ForgotPasswordInput>
    ) {
      state.forgotPassword = running()
    },
    forgotPasswordSuccess(state) {
      state.forgotPassword = success()
    },
    forgotPasswordFailure(state) {
      state.forgotPassword = faulted()
    },
    forgotPasswordClear(state) {
      state.forgotPassword = idle()
    },

    changePasswordStarted(
      state,
      payloadAction: PayloadAction<ChangePasswordInput>
    ) {
      state.changePassword = running()
    },
    changePasswordSuccess(state) {
      state.changePassword = success()
    },
    changePasswordFailure(
      state,
      action: PayloadAction<PasswordChangeError | undefined>
    ) {
      state.changePassword = faulted(action.payload)
    },
    changePasswordClear(state) {
      state.changePassword = idle()
    },

    ssoLoginCallbackStarted(
      state,
      action: PayloadAction<SSOLoginCallbackInput>
    ) {
      state.ssoLoginCallback = running()
    },
    ssoLoginCallbackCompleted(state) {
      state.ssoLoginCallback = success()
    },
    ssoLoginCallbackError(state) {
      state.ssoLoginCallback = faulted()
    },

    setAuthenticated(state, action: PayloadAction<AuthenticationData>) {
      state.authenticated = true
      state.token = action.payload.token
      state.profile = action.payload.profile
      state.userRole = action.payload.userRole
    },
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.profile = action.payload
    },
    setAnonymous(state) {
      state.authenticated = false
      state.loginError = false
      state.profile = null
      state.userRole = null
      state.isLoading = false
    },
  },
  initialState,
})

const { name: authName, actions: sliceActions, reducer: authReducer } = slice

const authActions = {
  ...sliceActions,
}

export { authName, authReducer, authActions }
