import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import common from "./translations/it/common.json"
import authForgotPassword from "./translations/it/auth/forgot-password.json"
import authLogin from "./translations/it/auth/login.json"
import adCallback from "./translations/it/auth/ad-callback.json"
import authPasswordReset from "./translations/it/auth/password-reset.json"
import featuresCustomersForm from "./translations/it/features/customers/form.json"
import featuresCustomersTable from "./translations/it/features/customers/table.json"
import emailLogsTable from "./translations/it/features/email-logs/table.json"
import emailLogsCommon from "./translations/it/features/email-logs/common.json"
import featuresFoldersForm from "./translations/it/features/folders/form.json"
import featuresFoldersTable from "./translations/it/features/folders/table.json"
import featuresProfileForm from "./translations/it/features/profile/form.json"
import featuresSellersForm from "./translations/it/features/sellers/form.json"
import featuresSellersTable from "./translations/it/features/sellers/table.json"
import featuresTemplatesForm from "./translations/it/features/templates/form.json"
import featuresTemplatesTable from "./translations/it/features/templates/table.json"
import featuresTemporaryPasswordForm from "./translations/it/features/temporary-password/form.json"
import featuresUsersForm from "./translations/it/features/users/form.json"
import featuresUsersTable from "./translations/it/features/users/table.json"
import featuresReportForm from "./translations/it/features/report/form.json"
import featuresReportTable from "./translations/it/features/report/table.json"
import pagesAdmin from "./translations/it/pages/admin.json"
import pagesCustomers from "./translations/it/pages/customers.json"
import pagesDashboard from "./translations/it/pages/dashboard.json"
import pagesFolders from "./translations/it/pages/folders.json"
import pagesEmailLogs from "./translations/it/pages/email-logs.json"
import pagesProfile from "./translations/it/pages/profile.json"
import pagesSellersAdmin from "./translations/it/pages/sellers.json"
import pagesTemplates from "./translations/it/pages/templates.json"
import pagesReport from "./translations/it/pages/report.json"
import pagesTemporaryPassword from "./translations/it/pages/temporary-password.json"
import pagesUnauthorized from "./translations/it/pages/unauthorized.json"
import pagesSigningSuccess from "./translations/it/pages/signing-success.json"
import pagesSigningCanceled from "./translations/it/pages/signing-canceled.json"
import pagesSigningError from "./translations/it/pages/signing-error.json"
import pagesSigningVerify from "./translations/it/pages/signing-verify.json"

export const resources = {
  it: {
    common,
    "auth/forgot-password": authForgotPassword,
    "auth/login": authLogin,
    "auth/ad-callback": adCallback,
    "auth/password-reset": authPasswordReset,
    "features/customers/form": featuresCustomersForm,
    "features/customers/table": featuresCustomersTable,
    "features/email-logs/table": emailLogsTable,
    "features/email-logs/common": emailLogsCommon,
    "features/folders/form": featuresFoldersForm,
    "features/folders/table": featuresFoldersTable,
    "features/profile/form": featuresProfileForm,
    "features/sellers/table": featuresSellersTable,
    "features/sellers/form": featuresSellersForm,
    "features/templates/table": featuresTemplatesTable,
    "features/templates/form": featuresTemplatesForm,
    "features/report/table": featuresReportTable,
    "features/report/form": featuresReportForm,
    "features/temporary-password/form": featuresTemporaryPasswordForm,
    "features/users/table": featuresUsersTable,
    "features/users/form": featuresUsersForm,
    "pages/admin": pagesAdmin,
    "pages/customers": pagesCustomers,
    "pages/dashboard": pagesDashboard,
    "pages/folders": pagesFolders,
    "pages/email-logs": pagesEmailLogs,
    "pages/profile": pagesProfile,
    "pages/report": pagesReport,
    "pages/sellers": pagesSellersAdmin,
    "pages/templates": pagesTemplates,
    "pages/temporary-password": pagesTemporaryPassword,
    "pages/unauthorized": pagesUnauthorized,
    "pages/signing-success": pagesSigningSuccess,
    "pages/signing-canceled": pagesSigningCanceled,
    "pages/signing-error": pagesSigningError,
    "pages/signing-verify": pagesSigningVerify,
  },
} as const

export const i18nInitialize = () =>
  i18n.use(initReactI18next).init({
    fallbackLng: "it",
    ns: Object.keys(resources.it),
    resources,
  })
