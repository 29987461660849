import { useAppSelector } from "@modules/store/redux"
import LogRocket from "logrocket"
import { useEffect } from "react"

export const useLogRocket = () => {
  const { profile, authenticated } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID) {
      console.log("logrocket init")
      LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID)
    }
  }, [])

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID &&
      authenticated &&
      profile
    ) {
      console.log("logrocket identify", profile)
      LogRocket.identify(profile.email, {
        name: profile.firstName + " " + profile.lastName,
        email: profile.email,
      })
    }
  }, [profile])
}
