import { TemplateDto } from "@api/backend"
import { createContext } from "react"

export interface DocumentTemplates {
  templates: TemplateDto[]
  initialized: boolean
  reload: () => void
}

export const DocumentTemplatesContext = createContext<DocumentTemplates>({
  initialized: false,
  templates: [],
  reload: () => {},
})
