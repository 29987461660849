import { Dialog, Transition } from "@headlessui/react"
import Typography from "@ui/atoms/Typography"
import clsx from "clsx"
import { Fragment, ReactNode } from "react"

export type ModalWidth = "sm" | "md" | "lg" | "xl" | "fullScreen"

interface ModalProps {
  title?: ReactNode
  children: ReactNode
  open: boolean
  onClose?: () => void
  width?: ModalWidth
  classes?: {
    title?: string
    panel?: string
    content?: string
  }
}

const Modal = ({
  title,
  children,
  open,
  onClose,
  classes,
  width,
}: ModalProps) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={() => onClose?.()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-25"
          leave="ease-in duration-200"
          leaveFrom="opacity-25"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-negative" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "transform overflow-hidden rounded-2xl bg-paper text-left align-middle shadow-xl transition-all",
                  {
                    "min-w-[min(600px,_98vw)]": width === "sm",
                    "min-w-[min(800px,_98vw)]": width === "md",
                    "min-w-[min(1200px,_98vw)]": width === "lg",
                    "min-w-[min(1600px,_98vw)]": width === "xl",
                    "min-w-[100vw] min-h-screen": width === "fullScreen",
                    "my-8": width !== "fullScreen",
                  },
                  classes?.title
                )}
              >
                {title && (
                  <Dialog.Title
                    as="div"
                    className={clsx(
                      "text-lg font-medium leading-6 text-gray-900 p-8 bg-grey-light",
                      classes?.title
                    )}
                  >
                    <Typography variant="h5">{title}</Typography>
                  </Dialog.Title>
                )}
                <div
                  className={clsx("min-w-[21.875rem] p-8", classes?.content)}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
