import { PayloadAction } from "@reduxjs/toolkit"
import { SSOLoginCallbackInput } from "../state/types"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put } from "typed-redux-saga"
import { authActions } from "../state"
import { adLoginCallbackPagePath } from "@routing/paths"
import { clientAbsoluteUrl } from "@routing/urls"
import { extractUserProfile, extractUserRole } from "../converters/user"

export function* ssoLoginCallback(
  action: PayloadAction<SSOLoginCallbackInput>
) {
  try {
    const api = yield* getSagaContext("api")
    const result = yield* call(api.auth.getActiveDirectoryUserAccessToken, {
      code: action.payload.code,
      redirectUri: clientAbsoluteUrl(adLoginCallbackPagePath()),
    })

    yield* call(api.setToken, result.accessToken)
    const profile = yield* call(api.auth.profile)

    yield* put(authActions.ssoLoginCallbackCompleted())
    yield* put(authActions.loginSuccess())
    yield* put(
      authActions.setAuthenticated({
        token: result.accessToken,
        profile: extractUserProfile(profile),
        userRole: extractUserRole(profile),
      })
    )
  } catch (error) {
    console.log("generic sso login callback error", error)
    yield* put(authActions.ssoLoginCallbackError())
  }
}
