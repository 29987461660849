import jwtDecode from "jwt-decode"

export const decodeJwt = <T>(token: string) => jwtDecode(token) as T

export const isTokenExpired = (token: string, when: Date) => {
  try {
    const jwt = decodeJwt(token) as any
    return when.getTime() > jwt.exp * 1000
  } catch (e) {
    console.error("Error while decoding jwt", e)
    return true
  }
}
