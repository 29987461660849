import { HexColor, InvalidColorError, RgbaColor, RgbColor } from "./types"
import { isValidHexColor, isValidRgbaColor, isValidRgbColor } from "./utils"

export const parseRgbColor = (rgbInput: string): RgbColor => {
  if (!isValidRgbColor(rgbInput)) {
    throw new InvalidColorError(`Invalid RGB color: ${rgbInput}`)
  }
  const sep = rgbInput.includes(",") ? "," : " "
  const rgbStr = rgbInput
    .toLowerCase()
    .replace("rgb(", "")
    .replace(")", "")
    .split(sep)
  const rgbValues = []

  // convert %s to 0–255
  for (const R in rgbStr) {
    const r = rgbStr[R]
    if (R.includes("%")) {
      rgbValues.push(Math.round((parseInt(r) / 100) * 255))
    } else {
      rgbValues.push(parseInt(r))
    }
  }

  return {
    r: rgbValues[0],
    g: rgbValues[1],
    b: rgbValues[2],
  }
}

export const parseRgbaColor = (rgbaInput: string): RgbaColor => {
  if (!isValidRgbaColor(rgbaInput)) {
    throw new InvalidColorError(`Invalid RGBA color: ${rgbaInput}`)
  }
  const sep = rgbaInput.includes(",") ? "," : " "
  let rgbaStr = rgbaInput
    .toLowerCase()
    .replace("rgba(", "")
    .split(")")[0]
    .split(sep)

  // strip the slash if using space-separated syntax
  if (rgbaInput.includes("/")) {
    rgbaStr = rgbaStr.splice(3, 1)
  }

  const rgbaValues = []
  for (const R in rgbaStr) {
    const r = rgbaStr[R]
    if (r.includes("%")) {
      const p = (parseInt(r) - 1) / 100

      if (parseInt(R) < 3) {
        rgbaValues.push(Math.round(p * 255))
      } else {
        rgbaValues.push(p)
      }
    } else {
      rgbaValues.push(parseInt(r))
    }
  }

  return {
    r: rgbaValues[0],
    g: rgbaValues[1],
    b: rgbaValues[2],
    a: rgbaValues[3],
  }
}

export const parseHexColor = (hexInput: string): HexColor => {
  if (!isValidHexColor(hexInput)) {
    throw new InvalidColorError(`Invalid HEX color: ${hexInput}`)
  }
  const hex = hexInput.replace("#", "")
  if (hex.length === 3) {
    return {
      rHex: hex[0] + hex[0],
      gHex: hex[1] + hex[1],
      bHex: hex[2] + hex[2],
    }
  }

  if (hex.length === 6) {
    return {
      rHex: hex[0] + hex[1],
      gHex: hex[2] + hex[3],
      bHex: hex[4] + hex[5],
    }
  }

  throw new InvalidColorError(`Invalid HEX color: ${hexInput}`)
}
