import { put, call, select } from "typed-redux-saga"
import { addSeconds } from "date-fns"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { authActions } from "../state"
import { sleep } from "@utils/threading"
import { RootState } from "@modules/store/redux/rootReducer"

const JWT_CHECK_INTERVAL_SECONDS = 60 * 5

/**
 * It calculates a datetime so that the token is considered as expired before the real expiration date
 * by 2 times JWT_CHECK_INTERVAL_SECONDS in order to make sure to logout the user before the
 * real token expiration data
 *
 * @param now
 * @returns
 */
const calculateTokenExpirationRef = (now: Date) =>
  addSeconds(now, 2 * JWT_CHECK_INTERVAL_SECONDS)

export function* watchTokenExpirationSaga() {
  const token = yield* select((state: RootState) => state.auth.token)
  if (!token) {
    return
  }

  const auth = yield* getSagaContext("auth")
  const isExpired = yield* call(
    auth.isTokenExpired,
    token,
    calculateTokenExpirationRef(new Date())
  )
  if (!isExpired) {
    return
  }

  console.log("watchTokenExpirationSaga -> auth token expired")
  yield* put(authActions.setAnonymous())
}

/**
 * It checks the token periodically in order to see if it is expired
 * and in that case it kicks the user out and forces to login again
 */
export function* watchTokenSaga() {
  while (true) {
    yield* call(sleep, JWT_CHECK_INTERVAL_SECONDS * 1000)
    yield* call(watchTokenExpirationSaga)
  }
}
