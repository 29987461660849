import { getSagaContext } from "@modules/store/redux/sagaContext"
import { IStore } from "@modules/store/redux/store"
import { PayloadAction } from "@reduxjs/toolkit"
import { put, call, takeLatest } from "typed-redux-saga"
import { extractUserProfile, extractUserRole } from "../converters/user"
import { authActions } from "../state"
import { AuthenticationData } from "../types"

const JWT_STORAGE_KEY = "auth:jwt"

const getTokenFromStorage = (storage: IStore) =>
  storage.get<string>(JWT_STORAGE_KEY)

const saveTokenIntoStorage = (storage: IStore, token: string) =>
  storage.save(JWT_STORAGE_KEY, token)

const deleteTokenFromStorage = (storage: IStore) =>
  storage.delete(JWT_STORAGE_KEY)

function* loadCachedToken() {
  const storage = yield* getSagaContext("store")

  const cachedToken = yield* call(getTokenFromStorage, storage)
  if (!cachedToken) {
    yield* put(authActions.initializationCompleted())
    return
  }

  const auth = yield* getSagaContext("auth")
  if (auth.isTokenExpired(cachedToken, new Date())) {
    yield* call(deleteTokenFromStorage, storage)
    yield* put(authActions.initializationCompleted)
    return
  }

  const api = yield* getSagaContext("api")
  yield* call(api.setToken, cachedToken)

  const profile = yield* call(api.auth.profile)

  yield* put(
    authActions.setAuthenticated({
      token: cachedToken!,
      profile: extractUserProfile(profile),
      userRole: extractUserRole(profile),
    })
  )
  yield* put(authActions.initializationCompleted())
}

function* persistAuthToken(action: PayloadAction<AuthenticationData>) {
  const storage = yield* getSagaContext("store")
  yield* call(saveTokenIntoStorage, storage, action.payload.token)
}

function* clearAuthToken() {
  const storage = yield* getSagaContext("store")
  yield* call(deleteTokenFromStorage, storage)
}

/**
 * If token persistence is enabled (NEXT_PUBLIC_PERSIST_AUTH_TOKEN env key)
 * will persist every new jwt generated by auth0 into local storage
 * and it uses it for avoiding login process at every page refresh
 */
export function* authInitializeSaga() {
  const auth = yield* getSagaContext("auth")

  try {
    if (auth.persistJwt()) {
      console.log("authInitializeSaga -> auth token persist ENABLED")
      yield* call(loadCachedToken)
      yield* takeLatest(authActions.setAuthenticated, persistAuthToken)
      yield* takeLatest(authActions.setAnonymous, clearAuthToken)
    } else {
      console.log("authInitializeSaga -> auth token persist DISABLED")
      yield* put(authActions.initializationCompleted())
    }
  } catch (error) {
    console.error("authInitializeSaga -> error", error)
    yield* put(authActions.initializationCompleted())
  }
}
