import { User, UserRole as ApiUserRole } from "@api/backend"
import { UserProfile } from "../state/types"
import { UserRole } from "../types"

export const extractUserProfile = (user: User): UserProfile => ({
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  temporaryPassword: user.temporaryPassword ?? false,
  customers: user.customers ?? [],
})

export const extractUserRole = (user: User): UserRole => {
  const { role } = user
  switch (role) {
    case ApiUserRole.ADMIN:
      return "admin"
    case ApiUserRole.BACKOFFICE:
      return "backoffice"
    case ApiUserRole.SELLER:
      return "seller"
    case ApiUserRole.USER:
      return "customer"
  }
}
