import { trimStart } from "@utils/strings"

export const localizePath = (path: string, locale: string) => {
  if (locale === "it") {
    return path
  }

  return `/${locale}${path}`
}

export const absoluteUrl = (relativePath?: string) =>
  `${process.env.NEXT_PUBLIC_SITE_URL}/${trimStart(relativePath ?? "", "/")}`

export const clientAbsoluteUrl = (relativePath?: string) =>
  `${window.origin}/${trimStart(relativePath ?? "", "/")}`
