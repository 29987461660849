import { useAppDispatch } from "@modules/store/redux"
import { useEffect } from "react"
import { controlsActions } from "../state"

export const useControlsGlobalFunctions = () => {
  const dispatch = useAppDispatch()

  const enableDelete = () => dispatch(controlsActions.enableDelete())
  const disableDelete = () => dispatch(controlsActions.disableDelete())

  const enableFolderVerify = () =>
    dispatch(controlsActions.enableFolderVerify())
  const disableFolderVerify = () =>
    dispatch(controlsActions.disableFolderVerify())

  const enableJsonView = () => dispatch(controlsActions.enableJsonView())
  const disableJsonView = () => dispatch(controlsActions.disableJsonView())

  useEffect(() => {
    ;(window as any).IC = {
      enableDelete,
      disableDelete,
      enableFolderVerify,
      disableFolderVerify,
      enableJsonView,
      disableJsonView,
    }
  }, [])
}
