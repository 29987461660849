import { call, put } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { LoginInfo } from "../state/types"
import { authActions } from "../state"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { extractUserProfile, extractUserRole } from "../converters/user"

export function* loginSaga(action: PayloadAction<LoginInfo>) {
  try {
    const api = yield* getSagaContext("api")
    const result = yield* call(api.auth.login, {
      email: action.payload.email,
      password: action.payload.password,
    })

    if (!result.success || !result.token) {
      yield* put(
        authActions.loginFailure({
          error: result.error!,
          lockoutMinutes: result.lockoutMinutes ?? undefined,
        })
      )
      return
    }

    // api.setToken(result.token)
    yield* call(api.setToken, result.token!)
    const profile = yield* call(api.auth.profile)

    yield* put(authActions.loginSuccess())
    yield* put(
      authActions.setAuthenticated({
        token: result.token!,
        profile: extractUserProfile(profile),
        userRole: extractUserRole(profile),
      })
    )
  } catch (error) {
    console.log("generic login error", error)
    yield* put(authActions.loginFailure())
  }
}
