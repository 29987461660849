import { Theme } from "@ui/types"
import { breakpoints } from "@ui/utils/theme"
import { CSSProperties } from "react"

export const getTextVariable = (
  val: any | any[],
  customVal: any | any[],
  variable: string,
  options?: {
    valSuffix?: string
    valPrefix?: string
  }
) => {
  const data = getValues(val, customVal)

  if (data == null) {
    return null
  }
  const { values, customValues } = data
  const style = breakpoints.reduce((o, v, i) => {
    let val = customValues[i] ?? values[i]
    if (val == null) {
      return o
    }

    if (typeof val !== "object") {
      val = { light: val }
    }
    Object.entries(val).forEach((entry) => {
      const k = entry[0] as Theme
      let themeSuffix = ""
      if (k !== "light") {
        themeSuffix = "--" + k
      }
      if (customValues[i] == null) {
        const prefix = !!options?.valPrefix?.trim ? options.valPrefix + "-" : ""
        const valSuffix = !!options?.valSuffix?.trim
          ? "-" + options.valSuffix
          : ""
        val = `var(--${prefix}${entry[1]}${valSuffix})`
      } else {
        val = entry[1]
      }

      o[`--${variable}${breakpoints[i]}${themeSuffix}`] = val
    })

    return o
  }, {} as any)

  return style as CSSProperties
}

const getValues = (val: any | any[], customVal: any | any[]) => {
  if (val == null && customVal == null) {
    return null
  }
  const values: any[] = Array.isArray(val) ? val : [val]
  const customValues: any[] = Array.isArray(customVal) ? customVal : [customVal]
  return { values, customValues }
}
